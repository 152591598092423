import { Amplify } from 'aws-amplify';
import { Authenticator, Heading, useTheme, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import 'bulma/css/bulma.min.css';

import awsExports from './aws-exports';
import Akamai from './components/Akamai';

Amplify.configure(awsExports);

const components = {
  SignIn: {
    Header() {
      return <></>;
    },
    Footer() {

      return <></>;
    },
  },
  VerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },

  ConfirmVerifyUser: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const App = () => {
  return (
    <Authenticator
      components={components}
      hideSignUp={true}
    >
      {
        ({ signOut }) => (
          <Akamai signOut={signOut} />
        )
      }
    </Authenticator>
  );
};

export default App;
