const OptionItem = ({value}) => {
  return <option>{value}</option>;
}

export const YearMonthSelector = ({yearmonths, current, onChange}) => {
  const optionItems = yearmonths.map((ym) =>
    <OptionItem key={ym} value={ym} />
  );
  return (
    <div className="select">
      <select onChange={onChange} value={current}>
        {optionItems}
      </select>
    </div>
  );
}
