import ReactECharts from 'echarts-for-react';
import _ from 'lodash';
import { useRef } from 'react';

import { defaultOptions } from '../lib/chartOptions';

const prepareData = (data, by) => {
  const dates = Object.keys(data);
  var option = {
    xAxis: [
      {
        data: dates.map( (e) => e.slice(5) )
      }
    ],
    series: []
  };

  if ("sources" === by) {
    for(const src of Object.keys(data[dates[0]].sources)) {
      var source = {
        name: src,
        type: 'bar',
        stack: 'mystack',
        emphasis: {
          focus: 'series'
        },
        data: []
      };

      for(const k of Object.keys(data)) {
        const elem = data[k].sources;
        source.data.push(elem[src]);
      }

      option.series.push(source);
    };
  }
  else {
    for(const src of Object.keys(data[dates[0]].specialties)) {
      var specialties = {
        name: src,
        type: 'bar',
        stack: 'mystack',
        emphasis: {
          focus: 'series'
        },
        data: []
      };

      for(const k of Object.keys(data)) {
        const elem = data[k].specialties;
        specialties.data.push(elem[src]);
      }

      option.series.push(specialties);
    };
  }
  return option;
};

export const Chart = ({data, by, showLoading}) => {
  const instance = useRef(null);

  let options = _.cloneDeep( defaultOptions );

  if(data.length !== 0) {
    const dynamicOptions = prepareData(data, by);
    options = _.merge(_.cloneDeep( defaultOptions ), dynamicOptions);
    showLoading = false;
  }

  return (
    <div className="container">
      <ReactECharts
        ref={instance}
        option={options}
        notMerge={true}
        showLoading={showLoading}
        style={{ height: 520 }} />
    </div>
  );
};
