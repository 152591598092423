import { Auth, API } from 'aws-amplify';
import { format } from 'date-fns';

import { useState, useEffect } from 'react';

import { Chart } from './Chart';
import { Header } from './Header';
import { Footer } from './Footer';

import { YearMonthSelector } from './YearMonthSelector';

function Akamai( {signOut} ) {
  const [token, setToken] = useState(null);
  const [yearMonthList, setYearMonthList] = useState([]);

  const [country, setCountry] = useState("fr");
  const [by, setBy] = useState("sources");
  const [yearmonth, setYearmonth] = useState(`${format(new Date(), 'yyyyMM')}`);

  const [showLoading, setShowLoading] = useState(true);
  const [graphdata, setGraphdata] = useState([]);

  const getToken = async () => {
    try {
      const userToken = await Auth.currentSession();
      return userToken.idToken.jwtToken;
    }
    catch(err) {
      console.log(err);
      return null;
    }
  }

  const getData = async (jwtToken, country, yearmonth) => {
    const apiName = "akamaieurapi";
    const path = `/eurapi/${country.toLowerCase()}/${yearmonth}`;
    const myInit = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: jwtToken
      }
    };
    return API.get(apiName, path, myInit);
  }

  useEffect(() => {
    (async () => {
      const jwtToken = await getToken();
      setToken(jwtToken);

      let response = await getData(jwtToken, 'fr', 'all');
      response = response.sort().reverse();
      setYearMonthList(response);
    })();
  }, []);

  useEffect(() => {
    setShowLoading(true);
    (async () => {
      if(token) {
        let response = await getData(token, country, yearmonth);
        setGraphdata(response);
      }
    })();
  }, [token, country, yearmonth]);

  return (
        <>
          <Header signOut={signOut} />
          <div className="columns is-gapless">
            <div className="column is-one-fifth">
              <section className="section">
                <div className="container">
                  <p className="menu-label">
                    Country
                  </p>
                  <ul className="menu-list">
                    <li><a className={ country === "fr" ? "is-active" : ""} onClick={() => { setCountry("fr"); }}>France</a></li>
                    <li><a className={ country === "es" ? "is-active" : ""} onClick={() => { setCountry("es"); }}>Spain</a></li>
                  </ul>
                  <p className="menu-label">
                    By
                  </p>
                  <ul className="menu-list">
                    <li><a className={ by === "sources" ? "is-active" : ""} onClick={() => { setBy("sources"); }}>Sources</a></li>
                    <li><a className={ by === "speciality" ? "is-active" : ""} onClick={() => { setBy("speciality"); }}>Speciality</a></li>
                  </ul>
                  <p className="menu-label">
                    Month
                  </p>
                  <ul className="menu-list">
                    <li>
                      <YearMonthSelector yearmonths={yearMonthList} current={yearmonth} onChange={(e) => { setYearmonth(e.target.value); }} />
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <div className="column">
              <Chart data={graphdata} by={by} showLoading={showLoading} />
            </div>
          </div>
          <Footer />
        </>
  );
}

export default Akamai;
